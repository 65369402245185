import { http } from "../support/http";

const digitalPostmanApi = () => {
    return {
        list: () => {
            http.defaults.baseURL = `${process.env.VUE_APP_API_ROBBU}/v1`
            return http.get('digitalpostman')
        },
        store: (data) => {
            http.defaults.baseURL = `${process.env.VUE_APP_API_ROBBU}/v2`
            return http.post('digitalpostman', data)
        },
        callback: (url, data) => {
            http.defaults.baseURL = url
            return http.post(null, data)
        },        
    }
}

export default digitalPostmanApi