<template>
  <div
    v-click-outside="hideOptions"
    class="select-input"
    :class="{ error, filled: !!value, opened: showOptions }"
  >
    <div class="content">
      <div v-if="icon" class="icon">
        <span class="material-icons-outlined">{{ icon }}</span>
      </div>
      <transition name="fade">
        <div v-if="!showOptions" class="chevron">
          <span class="material-icons-outlined">expand_more</span>
        </div>
      </transition>
      <label :for="id">{{ placeholder }}</label>
      <div class="input">
        <a class="value" href="#" @click.prevent="toggleOptions">
          <transition name="fade" mode="out-in">
            <div v-if="selectedOption" :key="selectedOption[optionValue]">
              {{ selectedOption[optionName] }}
            </div>
          </transition>
        </a>
        <transition name="fade">
          <ul v-if="showOptions" class="options scrollbar">
            <li
              v-for="option in options"
              :key="option[optionValue]"
              :class="{ active: isSelectedOption(option) }"
            >
              <a href="#" @click.prevent="input(option)">{{
                option[optionName]
              }}</a>
            </li>
          </ul>
        </transition>
      </div>
    </div>
    <transition name="fade">
      <div v-if="description" class="description">
        <v-input-description :error="error" :description="description" />
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

import VInputDescription from "@/components/forms/VInputDescription";

export default {
  name: "VSelectInput",
  props: {
    description: {
      default: null,
      type: String,
    },
    error: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: null,
      type: String,
    },
    options: {
      default: () => [],
      type: Array,
    },
    optionName: {
      default: "name",
      type: String,
    },
    optionValue: {
      default: "id",
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    type: {
      default: "text",
      type: String,
    },
    value: {
      default: "text",
    },
  },
  data() {
    return {
      id: `input-${this._uid}`,
      showOptions: false,
      selectedOption: null,
    };
  },
  methods: {
    input(option) {
      this.hideOptions();

      if (this.selectedOption && this.selectedOption === option) return;

      this.selectedOption = option;

      this.$emit("input", option ? option[this.optionValue] : null);
    },
    hideOptions() {
      this.showOptions = false;
    },
    isSelectedOption(option) {
      if (this.selectedOption)
        return (
          option[this.optionValue] === this.selectedOption[this.optionValue]
        );

      return false;
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    VInputDescription,
  },
  mounted() {
    setTimeout(() => {
      if (this.value)
        this.selectedOption = this.options.find(
          (option) => option[this.optionValue] === this.value
        );
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.select-input {
  margin-bottom: 20px;

  .content {
    position: relative;

    .icon {
      position: absolute;
      top: 13px;
      color: $borderColor;
      pointer-events: none;

      & ~ label {
        left: 35px;
      }

      & ~ .input {
        .value {
          padding: 20px 5px 0 35px;
        }
      }
    }

    .chevron {
      position: absolute;
      top: 13px;
      right: 5px;
      color: $borderColor;
    }

    label {
      position: absolute;
      top: 16px;
      left: 0;
      color: $borderColor;
      font-size: 16px;
      pointer-events: none;
      transition: 0.4s;
    }

    .input {
      position: relative;

      .value {
        display: block;
        border-bottom: 1px solid $borderColor;
        padding: 20px 0 0 0;
        width: 100%;
        height: 50px;
        color: $black;
        font-size: 16px;
        font-family: $roboto;
      }

      .options {
        position: absolute;
        background: #fff;
        top: 100%;
        margin-top: 4px;
        border: 1px solid $borderColor;
        border-radius: 6px;
        padding: 5px 0;
        width: 100%;
        max-height: 280px;
        box-shadow: 0 5px 10px 0 $borderColor;
        overflow: auto;
        z-index: 10;

        li {
          a {
            display: block;
            padding: 15px 20px;
            color: $purple;
            transition: 0.4s;

            &:hover,
            &:focus {
              background: rgba($purple, 0.05);
            }
          }

          &.active {
            background: rgba($purple, 0.05);

            a {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .description {
    margin-top: 6px;
  }

  &.opened {
    .content {
      .icon {
        color: $purple;
      }

      .input {
        .value {
          border-bottom: 1px solid $purple;
        }
      }
    }
  }

  &.filled {
    .content {
      label {
        top: 2px;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }

  &.error {
    .content {
      input {
        border-bottom: 1px solid $red;
      }
    }
  }
}
</style>