<template>
  <div class="input" :class="{ error, filled: !!value }">
    <div class="content">
      <div v-if="icon" class="icon">
        <span class="material-icons-outlined">{{ icon }}</span>
      </div>
      <label>
        <div class="placeholder">{{ placeholder }}</div>
        <transition name="fade">
          <div v-if="value" class="selected-file">
            {{ fileName }}
          </div>
        </transition>
        <input
          type="file"
          accept=".pdf,application/pdf"
          @change="handleFileChange"
        />
      </label>
    </div>
    <transition name="fade">
      <div v-if="description" class="description">
        <v-input-description :error="error" :description="description" />
      </div>
    </transition>
  </div>
</template>

<script>
import VInputDescription from "@/components/forms/VInputDescription";

export default {
  name: "VFileInput",
  props: {
    description: {
      default: null,
      type: String,
    },
    error: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: null,
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    value: {
      default: null,
    },
  },
  data() {
    return {
      id: `input-${this._uid}`,
    };
  },
  computed: {
    fileName() {
      return this.value[0] ? this.value[0].name : "Selecione um arquivo";
    },
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files;
      this.$emit("input", file);
    },
  },
  components: {
    VInputDescription,
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.input {
  margin-bottom: 20px;

  .content {
    position: relative;

    .icon {
      position: absolute;
      top: 13px;
      color: $borderColor;
      pointer-events: none;

      & ~ label {
        padding: 20px 5px 0 35px;

        .placeholder {
          left: 35px;
          font-weight: 100;
        }
      }
    }

    label {
      display: block;
      position: relative;
      border-bottom: 1px solid $borderColor;
      padding: 20px 0 0 0;
      width: 100%;
      height: 50px;
      color: $black;
      font-size: 16px;
      font-family: $roboto;
      cursor: pointer;
      transition: 0.4s;

      .placeholder {
        position: absolute;
        top: 16px;
        left: 0;
        color: $borderColor;
        font-size: 16px;
        transition: 0.4s;
      }

      .selected-file {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      input {
        display: none;
      }
    }
  }

  .description {
    margin-top: 6px;
  }

  &.filled {
    .content {
      label {
        .placeholder {
          top: 2px;
          font-size: 11px;
          font-weight: 500;
        }
      }
    }
  }

  &.error {
    .content {
      label {
        border-bottom: 1px solid $red;
      }
    }
  }
}
</style>