<template>
  <div class="ui-list">
    <v-block :border="false" :background="true" :shadow="true" :shadowElevation="1">
      <v-block-content>
        <div class="grid-x align-middle margin-bottom-30">
          <div class="cell auto">
            <v-title>Contatos recentes</v-title>
          </div>
          <transition name="fade">
            <div v-if="items.length" class="cell shrink">
              <v-button
                type="submit"
                value="Atualizar"
                size="small"
                :loading="loadingItems"
                @click.prevent.native="fetchData(true)"
              />
            </div>
          </transition>
        </div>
        <transition-group
          v-if="items.length"
          name="fade"
          tag="div"
          class="grid-x grid-margin-x contacts fix-margin-bottom"
        >
          <div
            v-for="item in items"
            :key="item.postmanId"
            class="cell large-6 contact"
          >
            <div class="content">
              <v-avatar :text="item.name" size="small" />
              <div class="infos">
                <div class="name">{{ item.name }}</div>
                <div class="document">Cpf/Cnpj: {{ item.document }}</div>
                <div class="wallet">
                  Segmento: {{ resolveSegment(item.segment) }}
                </div>
              </div>
              <v-tag
                :color="getCheckColor(item.check)"
                size="small"
                :value="getCheckValue(item.check)"
              />
            </div>
            <div class="bottom">
              <div class="code">
                Código: <span>{{ item.token }}</span>
              </div>
              <div class="date">
                {{ item.sent | moment("DD/MM/YYYY LTS") }}
              </div>
            </div>
          </div>
        </transition-group>
        <div class="grid-x grid-margin-x contacts fix-margin-bottom" v-else>
          <div class="not-found">Nenhum registro encontado</div>
        </div>
      </v-block-content>
    </v-block>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import digitalPostmanApi from "@/api/postman";
import WebsocketService from "@/support/services/websocket";

import VBlock from "@/components/blocks/VBlock";
import VBlockContent from "@/components/blocks/VBlockContent";
import VAvatar from "@/components/commons/VAvatar";
import VTag from "@/components/commons/VTag";
import VTitle from "@/components/commons/VTitle";
import VButton from "@/components/forms/VButton";

export default {
  name: "UiList",

  components: {
    VBlock,
    VBlockContent,
    VAvatar,
    VTag,
    VTitle,
    VButton,
  },

  data() {
    return {
      processing: false,
      pulse: null,
      items: [],
      loadingItems: false,
    };
  },

  computed: {
    ...mapGetters({
      userGetter: "auth/user",
    }),
  },

  methods: {
    getCheckValue(value) {
      return value ? `Validado` : `Não validado`;
    },

    getCheckColor(value) {
      return value ? `green` : `red`;
    },

    resolveSegment(segment) {
      return segment;
    },

    fetchData(showLoading = false) {
      if (this.processing) return;

      this.processing = true;

      if (showLoading) this.loadingItems = true;

      digitalPostmanApi()
        .list()
        .then(({ data }) => {
          this.items = data;
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
        })
        .finally(() => (this.loadingItems = false));
    },
  },

  created() {
    this.fetchData();
    this.$bus.$on("file-sent-success", (data) => this.fetchData());
  },

  mounted() {
    WebsocketService.socket().on("new-message", (data) => {
      const itemIndex = this.items.findIndex(
        (item) => item.postmanId === data.requestId
      );

      if (itemIndex > -1) {
        this.items.splice(itemIndex, 1, {
          ...this.items[itemIndex],
          check: data.validated,
        });
      }
    });
  },

  destroyed() {
    WebsocketService.socket().off("new-message");
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.ui-list {
  .not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.4);
    width: 100%;
    min-height: 50px;
  }
  .contacts {
    .contact {
      user-select: none;
      display: flex;
      margin-bottom: 15px;
      background: rgba(#f0f2f5, .5);
      box-shadow: 1px 1px 1px darken(#f0f2f5,05);
      border-radius: 6px;
      padding: 12px;
      flex-direction: column;
      
      .content {
        display: flex;
        margin-bottom: 12px;
        align-items: center;

        .avatar {
          margin-right: 10px;
        }

        .infos {
          .name {
            margin-bottom: 4px;
            font-weight: 500;
          }

          .document,
          .wallet {
            color: $gray;
            font-size: 12px;
          }
        }

        .tag {
          margin: 0 0 auto auto;
        }
      }

      .bottom {
        display: flex;
        align-items: center;

        .code {
          font-size: 12px;

          span {
            font-weight: 700;
          }
        }

        .date {
          margin-left: auto;
          color: $borderColor;
          font-size: 11px;
        }
      }
    }
  }
}
</style>
