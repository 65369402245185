<template>
  <v-base>
    <div class="grid-container">
      <div class="grid-x grid-margin-x grid-margin-y flex-dir-row-reverse">
         <div class="cell large-4 medium-5 small-12">
          <ui-form-contact />
        </div>
        <div class="cell large-8 medium-7 small-12">
          <ui-list />
        </div>
      </div>
    </div>
  </v-base>
</template>

<script>
import VBase from "@/views/layouts/VBase";
import UiList from "./components/UiList";
import UiFormContact from "./components/UiFormContact";

export default {
  name: "Dashboard",

  components: {
    VBase,
    UiList,
    UiFormContact
  },

  data() {
    return {};
  },

  created() {},
};
</script>
