import { http } from "../support/http";

const walletApi = () => {
    return {
        list: () => {
            http.defaults.baseURL = process.env.VUE_APP_API_INVENIOLIVE
            return http.get('wallets')
        }
    }
}

export default walletApi