<template>
  <div class="ui-form-contact">
    <v-block v-if="!success" :border="false" :background="true" :shadow="true" :shadowElevation="1">
      <v-block-content>
        <form autocomplete="off" @submit.prevent="formSubmit()">
          <div class="grid-x">
            <div class="cell">
              <v-input
                  v-model="form.senderName"
                  icon="face"
                  placeholder="Nome do destinatário"
                  :autofocus="true"
                  :error="form.$errors.has('senderName')"
                  :description="form.$errors.first('senderName')"
              />
            </div>

            <div class="cell">
              <v-input
                  v-model="form.contactPhoneNumber"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  icon="phone_iphone"
                  placeholder="DDD + Telefone"
                  :error="form.$errors.has('contactPhoneNumber')"
                  :description="form.$errors.first('contactPhoneNumber')"
              />
            </div>
            <div v-if="$whiteLabel.id !== 'robbu'" class="cell">
              <v-input
                  v-model="form.contactBirthdate"
                  v-mask="['##']"
                  icon="cake"
                  placeholder="Dia do nascimento"
                  :error="form.$errors.has('contactBirthdate')"
                  :description="form.$errors.first('contactBirthdate')"
              />
            </div>
            <div class="cell">
              <v-input
                  v-model="form.contactId"
                  v-mask="['###.###.###-##','##.###.###/####-##']"
                  icon="article"
                  placeholder="CPF/CNPJ"
                  :error="form.$errors.has('contactId')"
                  :description="form.$errors.first('contactId')"
              />
            </div>
            <div class="cell">
              <v-select-input
                  v-model="form.walletCode"
                  icon="card_travel"
                  :options="filteredWallets"
                  placeholder="Segmento"
                  optionValue="client_code"
                  :error="form.$errors.has('walletCode')"
                  :description="form.$errors.first('walletCode')"
                  @input="clearFieldError('walletCode')"
              />
            </div>

            <div class="cell documents">
              <div class="document-head">
                <div class="title">{{ getDocumentCollectionTitle }}
                  <span>Limite {{ `${this.documentCollection.length}/${this.maxDocuments}` }}</span></div>
                <div class="action">
                  <v-button size="tiny" :value="getButtonTextAdd" @click.prevent.native="addDocumentCollection"
                            :disabled="canAddDocuments"/>
                </div>
              </div>
              <div class="document" v-for="document in documentCollection" :key="document.id">
                <div class="action">
                  <div class="remove" @click="removeDocumentCollection(document.id)" v-if="canRemoveDocument">
                    <label>Remover</label>
                    <div class="icon">
                      <span class="material-icons-outlined">delete</span>
                    </div>
                  </div>
                </div>
                <div class="cell">
                  <v-select-input
                      v-model="document.file_format"
                      icon="format_list_numbered_rtl"
                      :options="documentFormatOptions"
                      placeholder="Formato do documento"
                      @input="validate(document, `file_format-${document.id}`)"
                  />
                </div>
                <div class="cell">
                  <v-file-input
                      v-if="document.file_format === 1"
                      v-model="document.file"
                      icon="picture_as_pdf"
                      placeholder="Arquivo do documento"
                      @input="fileSelected($event, document.id, `file-${document.id}`)"
                      :error="form.$errors.has(`file-${document.id}`)"
                      :description="form.$errors.first(`file-${document.id}`)"
                  />
                  <v-input
                      v-if="document.file_format === 2"
                      v-model="document.documentAddress"
                      icon="link"
                      placeholder="URL do documento online"
                      @input="validate(document, `documentAddress-${document.id}`)"
                      :error="form.$errors.has(`documentAddress-${document.id}`)"
                      :description="form.$errors.first(`documentAddress-${document.id}`)"
                  />
                  <v-input
                      v-if="document.file_format === 3 || document.file_format === 1"
                      v-model="document.documentCode"
                      icon="pin"
                      :placeholder="getPlaceholderDocumentCode"
                      @input="validate(document, `documentCode-${document.id}`)"
                      :error="form.$errors.has(`documentCode-${document.id}`)"
                      :description="form.$errors.first(`documentCode-${document.id}`)"
                  />
                </div>
              </div>
            </div>

            <div class="cell">
              <v-button
                  type="submit"
                  value="Enviar mensagem"
                  :disabled="canSubmitForm()"
                  :loading="processing"
              />
            </div>
          </div>
        </form>
      </v-block-content>
    </v-block>
    <v-block class="token-result-block" v-if="success" :border="false" :background="true" :shadow="true"
             :shadowElevation="1">
      <v-block-content>
        <v-title class="margin-bottom-25"
        >Documento enviado com sucesso!
        </v-title
        >
        <v-text v-if="$whiteLabel.id !== 'robbu'" class="margin-bottom-15">Informe para o cliente o código de segurança
          (O dia de nascimento):
        </v-text>
        <v-text v-else class="margin-bottom-15">Informe para o cliente o código de segurança:</v-text>
        <div class="token-result margin-bottom-45">{{ success.token }}</div>
        <v-button value="Novo arquivo" @click.prevent.native="sendNewFile"/>
      </v-block-content>
    </v-block>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Form} from "@nulvem/js-form";
import {mask} from "vue-the-mask";

import walletApi from "@/api/wallets";
import digitalPostmanApi from "@/api/postman";

import VText from "@/components/commons/VText";
import VTitle from "@/components/commons/VTitle";
import VBlock from "@/components/blocks/VBlock";
import VBlockContent from "@/components/blocks/VBlockContent";
import VButton from "@/components/forms/VButton";
import VInput from "@/components/forms/VInput";
import VFileInput from "@/components/forms/VFileInput";
import VSelectInput from "@/components/forms/VSelectInput";

const CALLBACK_URL = 'https://robbu-custom-viavarejo.azurewebsites.net/v1/ViaVarejoCallbackDigitalPostman'

export default {
  name: "UiFormContact",

  data() {
    return {
      processing: false,
      wallets: [],
      success: null,
      fileBase64: [],
      documentCollection: [],
      maxDocuments: 20,
      form: new Form({
        senderName: {
          value: null,
          validation: {
            rules: "required",
            messages: {
              required: "Informe o nome do destinatário",
            },
          },
        },
        contactPhoneNumber: {
          value: null,
          validation: {
            rules: "required",
            messages: {
              required: "Informe o telefone",
            },
          },
        },
        contactId: {
          value: null,
          validation: {
            rules: "required",
            messages: {
              required: "Informe o CPF ou CNPJ",
            },
          },
        },
        contactBirthdate: {
          value: null,
          validation: this.$whiteLabel.id !== 'robbu' ? {
            rules: "required",
            messages: {
              required: "Informe o dia do nascimento",
            },
          } : {},
        },
        walletCode: {
          value: null,
          validation: {
            rules: "required",
            messages: {
              required: "Selecione o segmento",
            },
          },
        },
      }),
    };
  },

  computed: {
    ...mapGetters({
      userGetter: "auth/user",
    }),

    filteredWallets() {
      if (this.$whiteLabel.id !== 'robbu') return this.wallets

      return this.wallets.filter(wallet => !!wallet.client_code)
    },

    getButtonTextAdd() {
      return `Adicionar`
    },

    canRemoveDocument() {
      return this.documentCollection.length > 1
    },

    canAddDocuments() {
      return this.documentCollection.length >= this.maxDocuments
    },

    getDocumentCollectionTitle() {
      return this.documentCollection.length > 1 ? `Documentos` : `Documento`
    },

    getFirsnameSender() {
      const splited = this.userGetter.name.split(' ')
      return splited.length > 1 ? this.userGetter.name.split(' ')[0] : this.userGetter.name
    },

    getPlaceholderDocumentCode() {
      return this.form.file_format === 1 ? `Insira a linha digitável (opcional)` : `Insira a linha digitável`
    },

    getFilenameFromDocumentAddress() {

      if (this.form.file_format === 2 && this.form.documentAddress) {
        const splited = this.form.documentAddress.split('.')
        const extension = splited[splited.length - 1]
        let temp = null

        if (splited.length > 1 && splited[splited.length - 2]) {
          const canSplit = splited[splited.length - 2].split('/')
          if (canSplit.length > 0) {
            temp = canSplit[canSplit.length - 1]
          } else {
            temp = canSplit[0]
          }
        }

        return `${temp}.${extension}`
      }

      return null
    },

    documentFormatOptions() {
      return [
        {
          id: 1,
          name: "Documento PDF",
        },
        {
          id: 2,
          name: "Documento on-line",
        },
        {
          id: 3,
          name: "Linha digitável",
        },
      ];
    },
  },

  created() {
    this.fetchWallets()
    this.addDocumentCollection()
  },

  watch: {
    'form.file'(event) {
      const file = event[0];
      let reader = new FileReader();
      reader.onload = (() => {
        return (e) => {
          let binaryData = e.target.result;
          this.fileBase64 = window.btoa(binaryData);
        };
      })(file);

      reader.readAsBinaryString(file);
    },

    'form.senderName'(event) {
      this.validate('senderName', event)
    },

    'form.contactBirthdate'(event) {
      if (this.$whiteLabel.id !== 'robbu')
        this.validate('contactBirthdate', event)
    },

    'form.contactId'(event) {
      this.validate('contactId', event)
    },

    'form.contactPhoneNumber'(event) {
      this.validate('contactPhoneNumber', event)
    }
  },

  methods: {
    ...mapActions({
      authenticateUserAction: "auth/authenticateUser",
    }),

    fileSelected(event, id, formField) {
      // Remove o ID se já estiver na lista
      this.delBase64FromArray(id)


      const file = event[0];
      let reader = new FileReader();
      reader.onload = (() => {
        return (e) => {
          let binaryData = e.target.result;
          this.fileBase64.push({
            id,
            base64: window.btoa(binaryData)
          });
          // Remove a validação de input
          this.form.$errors.unset(formField);
        };
      })(file);

      reader.readAsBinaryString(file);
    },

    addDocumentCollection() {
      if (this.canAddDocuments) return

      const payload = {
        id: this.$uuid.v4(),
        file_format: 1,
        file: null,
        documentAddress: null,
        documentCode: null
      }

      this.documentCollection.push(payload)

      this.validate(payload, `file_format-${payload.id}`)
    },

    unsetAllFieldFromUuid(uuid) {
      this.form.$errors.unset(`file_format-${uuid}`);
      this.form.$errors.unset(`file-${uuid}`);
      this.form.$errors.unset(`documentAddress-${uuid}`);
      this.form.$errors.unset(`documentCode-${uuid}`);
    },

    removeDocumentCollection(id) {

      if (!this.canRemoveDocument) return

      // Remove base64 da lista se houver
      this.delBase64FromArray(id)

      // Remove o objeto
      this.delDocumentFromArray(id)

      // Remove todos os avisos de validação
      this.unsetAllFieldFromUuid(id)
    },

    delBase64FromArray(id) {
      this.fileBase64 = this.fileBase64.filter((item) => {
        return item.id !== id;
      });
    },

    delDocumentFromArray(id) {
      this.documentCollection = this.documentCollection.filter((item) => {
        if (item.id === id) {
          this.form.$errors.unset(item.id);
        }
        return item.id !== id;
      });
    },

    canSubmitForm() {
      return this.form.$errors.any()
    },

    clearFieldError(formField) {
      this.form.$errors.unset(formField);
    },

    validate(formField, value) {

      if (formField.file_format) {
        this.unsetAllFieldFromUuid(formField.id)
        if (formField.file_format === 1) { // Documento PDF
          this.form.$errors.unset(`file-${formField.id}`);
          if (!formField.file) {
            this.form.$errors.push(`file-${formField.id}`, 'Selecione um arquivo')
          }
        }

        if (formField.file_format === 2) { // Documento online
          this.form.$errors.unset(`documentAddress-${formField.id}`);
          if (!formField.documentAddress) {
            this.form.$errors.push(`documentAddress-${formField.id}`, 'Informe a URL do documento')
          }
        }

        if (formField.file_format === 3) { // Linha digitável
          this.form.$errors.unset(`documentCode-${formField.id}`);
          if (!formField.documentCode) {
            this.form.$errors.push(`documentCode-${formField.id}`, 'Informe a linha digitável')
          }
        }
      }


      if (formField === 'senderName') {
        this.form.$errors.unset(formField);
        if (!value.length) {
          this.form.$errors.push(formField, 'Informe o nome do destinatário')
        }
      }

      if (formField === 'contactBirthdate' && this.$whiteLabel.id !== 'robbu') {
        this.form.$errors.unset(formField);
        if (parseInt(value) > 31 || !value.length) {
          this.form.$errors.push(formField, 'Informe um valor entre 1 e 31')
        }
      }

      if (formField === 'contactPhoneNumber') {
        this.form.$errors.unset(formField);
        if (value.length < 14) {
          this.form.$errors.push(formField, 'Informe um número válido')
        }
      }

      if (formField === 'contactId') {
        this.form.$errors.unset(formField);
        if (value.length < 14) {
          this.form.$errors.push(formField, 'Informe todos os número do documento')
        }
      }

      this.canSubmitForm()
    },

    sendNewFile() {
      this.success = null
    },

    fetchWallets() {
      walletApi()
          .list()
          .then(({data}) => {
            this.wallets = data;
          });
    },

    processFileStack() {
      let stack = []

      this.documentCollection.map((item) => {
        if (item.file_format === 1 && item.file) { // Documento PDF
          stack.push({
            documentFileName: item.file[0].name,
            documentBase64: this.fileBase64.find(file => file.id === item.id).base64,
            documentCode: item.documentCode
          })
        } else if (item.file_format === 2 && item.documentAddress) { // Documento online
          stack.push({
            documentAddress: item.documentAddress
          })
        } else if (item.file_format === 3 && item.documentCode) { // Linha digitável
          stack.push({
            documentCode: item.documentCode
          })
        }
      })

      return stack
    },

    formSubmit() {
      if (this.processing) return;

      this.processing = true;

      this.form.$errors.clear();

      this.form
          .$validate()
          .then(() => {
            const {walletCode, contactPhoneNumber, senderName, contactId, contactBirthdate} = this.form.$values()

            const doc = contactId.replace(/[^0-9]/g, '')

            let data = {
              senderName: this.getFirsnameSender,
              contactName: senderName,
              contactId: contactId.replace(/[^0-9]/g, ''),
              contactBirthdate: this.$whiteLabel.id !== 'robbu' ? contactBirthdate.length === 1 ? `1901-01-0${contactBirthdate}` : `1901-01-${contactBirthdate}` : null,
              walletCode: walletCode,
              contactPhoneNumber: parseInt(`55` + contactPhoneNumber.replace(/[^0-9]/g, '')),
              callbackURL: this.$whiteLabel.id === 'via-varejo' ? CALLBACK_URL : null,
              invalidTokenMessage: this.$whiteLabel.id === 'via-varejo' ? 'Este canal está sendo utilizado exclusivamente para emissão deste boleto, em caso de duvidas favor entrar em contato com nossa central de renegociação de dívidas no número 0800 025-8880 ou através do WhatsApp: https://gsrvices.com/ViaVarejoAtendimento' : null,
              documentCollection: this.processFileStack()
            }

            digitalPostmanApi()
                .store(data)
                .then(async ({data}) => {

                  // ### Chamada removida do frontend. A API fará esse tratamento após o recebimento do callback.store()
                  // Chama o callback passando o requestID retornado no cadastro do documento
                  // await digitalPostmanApi().callback(CALLBACK_URL, {requestId: data.requestId, validated: false})

                  this.processing = false;
                  this.success = data
                  this.form.$reset()
                  this.fileBase64 = []
                  this.documentCollection = []
                  this.addDocumentCollection()
                  this.$bus.$emit('file-sent-success', data)
                  this.$n("Documento enviado com sucesso!")

                })
                .catch(error => {
                  this.processing = false;
                });
          })
          .catch(error => {
            this.processing = false;
            this.$n(
                "É necessário preencher todos os campos obrigatórios",
                "error"
            );
          });
    },
  },

  directives: {mask},

  components: {
    VText,
    VTitle,
    VBlock,
    VBlockContent,
    VButton,
    VInput,
    VFileInput,
    VSelectInput,
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.ui-form-contact {
  .token-result-block {
    text-align: center;

    .token-result {
      color: $black;
      font-size: 32px;
      font-weight: 700;
    }
  }

  .documents {
    .document-head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 20px;

      .title {
        font-size: 20px;
        display: flex;
        flex-direction: column;

        span {
          color: $borderColor;
          font-size: 12px;
        }
      }
    }

    .document {
      border: solid 2px rgb(238, 238, 238);
      padding: 14px 14px 0;
      border-radius: 6px;

      &:not(:first-child) {
        margin-top: 20px;
      }

      &:hover {
        background: rgba(0, 0, 0, .02);
      }

      .action {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      .remove {
        user-select: none;
        display: flex;
        align-items: center;
        width: fit-content;
        border-radius: 6px;
        padding: 2px 4px;

        .icon {
          display: flex;
          margin-left: 5px;
        }

        label {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, .3);
          padding-top: 1px;
          margin-left: 5px;
        }

        .material-icons-outlined {
          transition: initial;
          color: rgba(0, 0, 0, .3)
        }

        &:hover {
          cursor: pointer;
          background: rgba(red, .1);

          * {
            cursor: pointer;
            color: darken(red, 5)
          }
        }

        &:active {
          position: relative;
          top: 1px;
        }
      }
    }

    margin-bottom: 40px;
  }
}
</style>
