<template>
  <div class="tag" :class="[color, size]">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'PTag',
  props: {
    color: {
      default: 'yellow',
      type: String
    },
    size: {
      default: 'regular',
      type: String
    },
    value: {
      default: null,
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.tag {
  color: #fff;
  font-weight: 500;
  transition: .4s;

  &.big {
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 16px;
  }

  &.regular {
    border-radius: 6px;
    padding: 3px 8px;
    font-size: 14px;
  }

  &.small {
    border-radius: 4px;
    padding: 3px 6px;
    font-size: 12px;
  }

  &.super-small {
    border-radius: 4px;
    padding: 2px 5px;
    font-size: 10px;
  }

  &.red {
    background: $red;
  }

  &.green {
    background: $green;
  }
}
</style>